<template>
  <div>
    <!-- 编码字典 -->
    <div class="dict-left content-wrap">
      <div class="btn-wrap cfx">
        <el-button type="success" size="small" icon="el-icon-plus" @click="handleForm(false)" v-if="OA.includes('xtgl:zdgl:add')">添加</el-button>
      </div>
      <div v-if="dictTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
      <paged-table
        v-if="dictTotal>0"
        :data="dictList"
        :total="dictTotal"
        :loading="tLoading"
        :isShowSelection="false"
        defaultSortProp="id"
        width="120"
        v-model="queryParams"
        @refresh="refresh"
      > 
        <el-table-column sortable="custom" prop="id" show-overflow-tooltip label="id" width="150"></el-table-column>
        <el-table-column sortable="custom" prop="name" show-overflow-tooltip label="名称"></el-table-column>
        <el-table-column sortable="custom" prop="code" show-overflow-tooltip label="编码"></el-table-column>
        <el-table-column sortable="custom" prop="bsCode" show-overflow-tooltip label="业务编码"></el-table-column>
        <el-table-column sortable="custom" prop="status" show-overflow-tooltip label="状态" width="100" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'">{{ scope.row.status == 1 ? '正常' : '停用' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="right" label="操作" width="80" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleForm(true, scope.row)" icon="el-icon-edit" v-if="OA.includes('xtgl:zdgl:editor')" title="编辑"></el-button>
            <el-button size="mini" v-if="OA.includes('xtgl:zdgl:del')" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>

    <div class="dict-right content-wrap">
      <el-tree :data="treeData" :default-expanded-keys="expandedKeys" lazy node-key="code" ref="tree" :load="loadNode" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑字典' : '添加字典'" :visible.sync="aVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="字典名称：" prop="name">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="字典编码：" prop="code">
          <el-input v-model="form.code" clearable></el-input>
        </el-form-item>
        <el-form-item label="业务编码：" prop="bsCode">
          <el-input v-model="form.bsCode" clearable></el-input>
        </el-form-item>
        <el-form-item label="上级字典：" prop="pName">
          <el-input v-model="form.pName" disabled clearable></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" :rows="2" v-model="form.remark" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'dictManagement',
  components: {
    PagedTable
  },
  mixins: [pageMixin],
  data() {
    return {
      queryParams: {
        pid: 0,
      },
      expandedKeys:[],

      form:{
        id:'',
        name:'',
        code:'',
        bsCode:'',
        pid:'',
        pName:'',
        remark:'',
      },
      formRules:{
        name: [{ required: true, message: '请输入数据库名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择数据库类型', trigger: 'change' }],
        url: [{ required: true, message: '请输入链接地址', trigger: 'blur' }],
      },

      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },

      pageApi:'commonDictList',
      insertApi:'commonDictAdd',
      editorApi:'commonDictEdit',
      deleteApi:'commonDictDelete',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("systemDict", ["dictList", "dictTotal"]),
  },
  methods: {
    ...mapActions("systemDict",[
      "commonDictList",
      "commonDictAdd",
      "commonDictEdit",
      "commonDictDelete",
      "commonDictGetDictFCode",
      "commonDictGetDictFPid"
    ]),
    // 改成id
    async getDictTree(){
      this.treeData = await this.commonDictGetDictFCode({ code:'' })
    },
    async loadNode(node, resolve) {
      if (node.level === 0) {
        return ;
      }
      if (node.level > 0){
        let res = await this.commonDictGetDictFPid({ pid:node.data.id })
        return resolve(res)
      }
    },
    handleNodeClick(data, node){
      this.queryParams.pid = data.id
      this.form.pid = data.id
      this.form.pName = data.name
      this.refresh()
    }
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
    this.getDictTree()
  }
};
</script>
<style lang="scss" scoped>
  .dict-left { margin-right:250px; margin-top:0; }
  .dict-right { position: absolute; right:0; top:0; bottom:0; width:235px; margin-top:0;}
</style>